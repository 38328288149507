<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="">
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="flex items-center back-btn">
          <router-link to="/my">
            <i class="ti ti-chevron-left text-white back-btn-icon"></i>
          </router-link>
        </div>
        <div class="title-primary text-white text-title">Mã mời</div>
      </div>

      <div class="ranking">
        <div class="share-main">
          <div class="flex flex-col justify-center h-full w-full">
            <p class="ranking-title">Mã mời của bạn</p>
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <p class="ranking-subtitle">{{ user.invite_code }}</p>
              </div>
              <div class="ranking-discount" @click="copyInvite()">
                <p class="ranking-discount_text">
                  Sao chép<i class="ti ti-copy ms-1"></i>
                </p>
              </div>
            </div>
            <!-- <p class="ranking-title mt-3">Chia sẻ ngay</p>
            <div class="flex items-center justify-center gap-x-3 mt-2">
              <div class="share-icon">
                <img src="@/assets/img/facebook.png" alt="" />
              </div>
              <div class="share-icon">
                <img src="@/assets/img/instagram.png" alt="" />
              </div>
              <div class="share-icon">
                <img src="@/assets/img/twitter.png" alt="" />
              </div>
              <div class="share-icon">
                <img src="@/assets/img/line.png" alt="" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import network from '@/core/network';
export default {
    components: {Footer},
    data() {
        return {
          user: {}
        }
    },
    mounted() {
      this.getProfile();
    },
    methods: {
      getProfile() {
        network.get('/users/profile').then( res => {
          console.log(res.data.data);
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
      async copyInvite() {
        await navigator.clipboard.writeText(this.user.invite_code);
        this.$toast.open({
          message: 'đã sao chép',
          type: 'success',
          position: 'top',
          duration:3000,
        });
      }
    }
}
</script>

<style>

</style>